import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import "../assets/css/aboutme.css";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { makeStyles } from '@material-ui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box'

const theme = createTheme({
    breakpoints: {
        values: {
            xxs: 0, // small phone
            xs: 300, // phone
            sm: 600, // tablets
            md: 900, // small laptop
            lg: 1200, // desktop
            xl: 1536 // large screens
          }
    }
});



const AboutMePage = () => {

    const data = useStaticQuery(query);

    return (
        <Layout seo={data.strapiGlobal.defaultSeo}>
            <div className="uk-section">
                <div className="uk-container uk-container-large">          
                <div className="aboutme-container">
                    <ThemeProvider theme={theme}>
                        <Box 
                            sx={{
                                mt: "20px",
                                flexGrow: 1,
                                [theme.breakpoints.down("sm")]:{
                                    width: "100%"
                                },
                                [theme.breakpoints.up("sm")]:{
                                    width: "50%"
                                },
                                // width: {
                                //     sm: "50%",
                                //     [theme.breakpoints.down("sm")]: "calc(50% - 0.5rem)",
                                //     [theme.breakpoints.up("sm")]: "100%",
                                // },
                                // bgcolor: {

                                // }
                             }}
                        >
                            <img className="aboutme-welcomeimage" src={data.strapiAboutMe.WelcomeImage.url} alt="" />
                        </Box>
                    
                    {/* <div className="logo-container">
                        <img className="aboutme-welcomeimage" src={data.strapiAboutMe.WelcomeImage.url} alt="" />
                    </div>*/}
                    <Box 
                            sx={{
                                [theme.breakpoints.down("sm")]:{
                                    width: "100%"
                                },
                                [theme.breakpoints.up("sm")]:{
                                    width: "50%"
                                },
                            }}
                    >
                        <label className="aboutme-welcomemessage" dangerouslySetInnerHTML={{ __html: data.strapiAboutMe.WelcomeMessage}}></label>                        
                    </Box>
                    </ThemeProvider>            
                </div>
                </div>
            </div>
        </Layout>
    );
};
    
const query = graphql`
    query {   
        strapiHomepage{
            WelcomeImage {
                url
            }
            WelcomeMessage
        }
        strapiAboutMe {
            WelcomeMessage
            WelcomeImage {
                url
            }
        }
        strapiGlobal{
            defaultSeo {
                id
            }
        }
    }  
`;


export default AboutMePage;